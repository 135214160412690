import { action, makeObservable, observable } from "mobx";
import I18n from "../../../../core/localization/I18n";
import { DisposableModel } from "../../../../core/util/DisposableModel";
import ProjectsApi, { ProjectsApi as IProjectsApi } from "../../../../services/api/v2/projects/Projects.api";
import { ImpactReportingViewChartProps } from "./ImpactReportingView_ChartProps";
import Pages from "../../../../routes/InsightRoutes";
import { NavigateFunction } from "react-router-dom";
import ImpactReportingApi, {
  ImpactReportingApi as IImpactReportingApi
} from "../../../../services/api/v2/impactReporting/ImpactReporting.api";
import ImpactsApi, { ImpactsApi as IImpactsApi } from "../../../../services/api/v2/impacts/Impacts.api";
import { SHOW_IMPACT_ADVANCED_FILTER_MODAL } from "./ImpactReportingView_modals";
import { GridModalBuilder } from "../../../../core/grids/builder/directorBuilder/modals/GridModal_builder";
import LocalStorageService from "../../../../services/local/localStorageService/LocalStorageService";
import { ILocalStorageService } from "../../../../services/local/localStorageService/ILocalStorageService";
import ModalContext from "../../../../core/modalZ/context/ModalContext";
import { GridTypes } from "../../../../enums";

export class ImpactReportingViewModel extends DisposableModel {
  projectProvider: IProjectsApi;
  organisationId: number;
  projectId: number;
  impactProvider: IImpactsApi;
  impactReportingProvider: IImpactReportingApi;
  @observable isLoading: boolean = true;
  @observable impactCount: number;
  @observable impactGroupCount: number;
  @observable impacts: FP.Entities.IImpactSummary[];

  @observable byTypeChartDataProps: ImpactReportingViewChartProps;
  @observable byLocationChartDataProps: ImpactReportingViewChartProps;
  @observable byBusinessAreaChartDataProps: ImpactReportingViewChartProps;
  @observable byOwnerAreaChartDataProps: ImpactReportingViewChartProps;
  @observable byStakeholderSentimentChartDataProps: ImpactReportingViewChartProps;
  @observable byStakeholderCommitmentChartDataProps: ImpactReportingViewChartProps;
  @observable byStakeholderReceptivenessChartDataProps: ImpactReportingViewChartProps;
  navigate: NavigateFunction;

  @observable ragData: any[];
  averageDataConfidence: number = 0;

  authUser: FP.Entities.IUser;

  gridModalBuilder: GridModalBuilder;

  @observable filterSelectedItemIds: number[];
  localStorageService: ILocalStorageService;
  gridType: GridTypes;

  constructor(
    organisationId: number,
    projectId: number,
    authUser: FP.Entities.IUser,
    navigate: NavigateFunction,
    gridType: GridTypes
  ) {
    super();
    makeObservable(this);
    this.authUser = authUser;
    this.navigate = navigate;
    this.impactProvider = ImpactsApi;
    this.projectProvider = ProjectsApi;
    this.impactReportingProvider = ImpactReportingApi;
    this.projectId = projectId;
    this.organisationId = organisationId;

    this.setCountsForImpactAndImpactGroup();

    this.byTypeChartDataProps = new ImpactReportingViewChartProps(this.organisationId, this.projectId, navigate);
    this.byLocationChartDataProps = new ImpactReportingViewChartProps(this.organisationId, this.projectId, navigate);
    this.byBusinessAreaChartDataProps = new ImpactReportingViewChartProps(
      this.organisationId,
      this.projectId,
      navigate
    );
    this.byOwnerAreaChartDataProps = new ImpactReportingViewChartProps(this.organisationId, this.projectId, navigate);
    this.byStakeholderSentimentChartDataProps = new ImpactReportingViewChartProps(
      this.organisationId,
      this.projectId,
      navigate
    );
    this.byStakeholderCommitmentChartDataProps = new ImpactReportingViewChartProps(
      this.organisationId,
      this.projectId,
      navigate
    );
    this.byStakeholderReceptivenessChartDataProps = new ImpactReportingViewChartProps(
      this.organisationId,
      this.projectId,
      navigate
    );

    this.gridModalBuilder = new GridModalBuilder();
    this.localStorageService = LocalStorageService;
    this.gridType = gridType;
    this.initFilters();
  }

  initFilters = () => {
    let selectedItemsFromLocalStorage = this.localStorageService.get(
      this.getProjectProcessesReportingFiltersLocalStorageKey()
    );

    if (!selectedItemsFromLocalStorage) return;
    let items = selectedItemsFromLocalStorage.split(",").map(e => parseInt(e));
    this.setFilterSelectedItemIds(items);
  };

  getProjectProcessesReportingFiltersLocalStorageKey = () => {
    return `${this.gridType}-filter-selected-items-${this.projectId}`;
  };

  handleAdvancedFilterClick = async () => {
    this.gridModalBuilder
      .constructPopupModal()
      .setModalOptions(SHOW_IMPACT_ADVANCED_FILTER_MODAL(this.setFilterSelectedItemIds, this.filterSelectedItemIds))
      .generateModal();
  };

  @action
  setFilterSelectedItemIds = async (filterSelectedItemIds: any[]) => {
    this.filterSelectedItemIds = filterSelectedItemIds;
    if (filterSelectedItemIds) {
      this.localStorageService.set(
        this.getProjectProcessesReportingFiltersLocalStorageKey(),
        filterSelectedItemIds?.join(",")
      );
    } else {
      this.localStorageService.remove(this.getProjectProcessesReportingFiltersLocalStorageKey());
    }
    await this.onMount();
    ModalContext.hide();
  };

  clearBasicFilterSelectedItemIds = () => {
    this.setFilterSelectedItemIds(null);
    this.localStorageService.remove(this.getProjectProcessesReportingFiltersLocalStorageKey());
  };

  clearFilters = () => {
    this.setFilterSelectedItemIds(null);
    this.setFilterSelectedItemIds(null);
    this.localStorageService.remove(this.getProjectProcessesReportingFiltersLocalStorageKey());
  };

  onMount = async () => {
    this.setIsLoading(true);
    await this.loadChartData();
    this.setIsLoading(false);
  };

  onUnmount = () => {};

  @action
  setIsLoading = (isLoading: boolean) => {
    this.isLoading = isLoading;
  };

  @action
  loadImpactsByTypeData = async () => {
    const impactsByType = await this.impactReportingProvider.getImpactsByTypeBreakdownReport(
      this.organisationId,
      this.projectId,
      this.filterSelectedItemIds
    );

    if (!!impactsByType.payload && !impactsByType.isError) {
      this.setByTypeData(impactsByType.payload);
    }
  };

  @action
  loadImpactsByLocationData = async () => {
    const impactsByLocation = await this.impactReportingProvider.getImpactsByLocationBreakdownReport(
      this.organisationId,
      this.projectId,
      this.filterSelectedItemIds
    );

    if (!!impactsByLocation.payload && !impactsByLocation.isError) {
      this.setByLocationData(impactsByLocation.payload);
    }
  };

  @action
  loadImpactsByBusinessArea = async () => {
    const impactsByBusinessArea = await this.impactReportingProvider.getImpactsByBusinessAreaBreakdownReport(
      this.organisationId,
      this.projectId,
      this.filterSelectedItemIds
    );

    if (!!impactsByBusinessArea.payload && !impactsByBusinessArea.isError) {
      this.setByBusinessAreaData(impactsByBusinessArea.payload);
    }
  };
  @action
  loadImpactsByOwner = async () => {
    const impactsByOwner = await this.impactReportingProvider.getImpactsByOwnerBreakdownReport(
      this.organisationId,
      this.projectId,
      this.filterSelectedItemIds
    );

    if (!!impactsByOwner.payload && !impactsByOwner.isError) {
      this.setByOwnerData(impactsByOwner.payload);
    }
  };

  @action
  loadImpactsByStakeholderSentiment = async () => {
    const impactsByStakeholderSentiment =
      await this.impactReportingProvider.getImpactsByStakeholderSentimentBreakdownReport(
        this.organisationId,
        this.projectId,
        this.filterSelectedItemIds
      );

    if (!!impactsByStakeholderSentiment.payload && !impactsByStakeholderSentiment.isError) {
      this.setByStakeholderSentimentData(impactsByStakeholderSentiment.payload);
    }
  };

  @action
  loadImpactsByStakeholderCommitment = async () => {
    const impactsByStakeholderCommitment =
      await this.impactReportingProvider.getImpactsByStakeholderCommitmentBreakdownReport(
        this.organisationId,
        this.projectId,
        this.filterSelectedItemIds
      );

    if (!!impactsByStakeholderCommitment.payload && !impactsByStakeholderCommitment.isError) {
      this.setByStakeholderCommitmentData(impactsByStakeholderCommitment.payload);
    }
  };

  @action
  loadImpactsByStakeholderReceptiveness = async () => {
    const impactsByStakeholderReceptiveness =
      await this.impactReportingProvider.getImpactsByStakeholderReceptivenessBreakdownReport(
        this.organisationId,
        this.projectId,
        this.filterSelectedItemIds
      );

    if (!!impactsByStakeholderReceptiveness.payload && !impactsByStakeholderReceptiveness.isError) {
      this.setByStakeholderReceptivenessData(impactsByStakeholderReceptiveness.payload);
    }
  };

  @action
  loadImpacts = async () => {
    const impacts = await this.impactProvider.getGridDataByImpactIds(
      this.organisationId,
      this.projectId,
      this.filterSelectedItemIds
    );

    if (!!impacts.payload && !impacts.isError) {
      this.setData(impacts.payload.data);
      this.loadImpactLevelStatus();
    }
  };

  @action
  loadChartData = async () => {
    this.setIsLoading(true);
    const res = await Promise.all([
      this.loadImpactsByTypeData(),
      this.loadImpactsByLocationData(),
      this.loadImpactsByBusinessArea(),
      this.loadImpactsByOwner(),
      this.loadImpactsByStakeholderCommitment(),
      this.loadImpactsByStakeholderReceptiveness(),
      this.loadImpactsByStakeholderSentiment(),
      this.loadImpacts()
    ]);

    return res;
  };

  @action
  setByTypeData = chartData => {
    this.byTypeChartDataProps.setData(chartData);
    this.calculateAverageDataConfidence();
  };

  @action
  setByLocationData = chartData => {
    this.byLocationChartDataProps.setData(chartData);
    this.calculateAverageDataConfidence();
  };

  @action
  setByBusinessAreaData = chartData => {
    this.byBusinessAreaChartDataProps.setData(chartData);
    this.calculateAverageDataConfidence();
  };

  @action
  setByOwnerData = chartData => {
    this.byOwnerAreaChartDataProps.setData(chartData);
    this.calculateAverageDataConfidence();
  };

  @action
  setByStakeholderSentimentData = chartData => {
    this.byStakeholderSentimentChartDataProps.setData(chartData);
    this.calculateAverageDataConfidence();
  };

  @action
  setByStakeholderCommitmentData = chartData => {
    this.byStakeholderCommitmentChartDataProps.setData(chartData);
    this.calculateAverageDataConfidence();
  };

  @action
  setByStakeholderReceptivenessData = chartData => {
    this.byStakeholderReceptivenessChartDataProps.setData(chartData);
    this.calculateAverageDataConfidence();
  };

  @action
  setData = impacts => {
    this.impacts = impacts;
    this.impactCount = impacts.length;
  };

  @action
  setCountsForImpactAndImpactGroup = async () => {
    let res = await this.projectProvider.getImpactsAndImpactGroupsCount(this.organisationId, this.projectId);
    this.impactGroupCount = res.payload.impactGroupCount;
  };

  changeCurrentView = (newTabIndex: number) => {
    if (newTabIndex === 2) {
      this.navigate(
        Pages.projects.impacts.visualisations.impactAssesment.generateLink(this.organisationId, this.projectId)
      );
      return;
    }

    if (newTabIndex === 0) {
      this.navigate(Pages.projects.impactGroups.listView.generateLink(this.organisationId, this.projectId));
      return;
    }
    this.navigate(Pages.projects.impacts.listView.generateLink(this.organisationId, this.projectId));
  };

  @action
  calculateAverageDataConfidence = () => {
    let allDataConfidence: number[] = [
      this.byTypeChartDataProps.DataConfidence,
      this.byLocationChartDataProps.DataConfidence,
      this.byBusinessAreaChartDataProps.DataConfidence,
      this.byOwnerAreaChartDataProps.DataConfidence
      // this.byStakeholderSentimentChartDataProps.DataConfidence,
      // this.byStakeholderCommitmentChartDataProps.DataConfidence,
      // this.byStakeholderReceptivenessChartDataProps.DataConfidence
    ];

    this.averageDataConfidence = Math.round(
      allDataConfidence.reduce((partialSum, a) => partialSum + a, 0) / allDataConfidence.length
    );
  };

  @action
  loadImpactLevelStatus = () => {
    this.ragData = [
      {
        label: I18n.t("visualisations.level_high"),
        value: this.impacts.filter(e => e.impactLevel > 7).length,
        id: "high"
      },
      {
        label: I18n.t("visualisations.level_medium"),
        value: this.impacts.filter(e => e.impactLevel > 4 && e.impactLevel < 8).length,
        id: "medium"
      },
      {
        label: I18n.t("visualisations.level_low"),
        value: this.impacts.filter(e => e.impactLevel > -1 && e.impactLevel < 5).length,
        id: "low"
      },
      {
        label: I18n.t("visualisations.Unknown"),
        value: this.impacts.filter(e => e.impactLevel === -1 || e.impactLevel === 0).length,
        id: "unknown"
      }
    ];
  };

  advancedFilterClickFn = (setFilterSelectedItemIds: (e: number[]) => void, filterSelectedItemIds: number[]) => {
    return SHOW_IMPACT_ADVANCED_FILTER_MODAL(setFilterSelectedItemIds, filterSelectedItemIds);
  };
}
