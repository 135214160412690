import { ColDef } from "ag-grid-community";
import ProgressIndicatorModel from "../../../../../../components/widgets/ProgressIndicator/ProgressIndicator_model";
import ProcessesApi from "../../../../../../services/api/v2/processes/Processes.api";
import GridToastService from "../../../../../../services/local/gridToastService/GridToastService";
import I18n from "../../../../../localization/I18n";
import { CommonColDefFieldNamesEnum, ProcessColDefFieldNamesEnum } from "../../../enums/AgGridColDefFieldNameEnum";
import { SelectionColumnBuilder } from "../../columns/commonColumns/SelectionColumn/SelectionColumn_builder";
import { SimpleTextColumnBuilder } from "../../columns/commonColumns/SimpleTextColumn/SimpleTextColumn_builder";
import { ProcessHierarchField } from "../../utils/GridFields";
import { BaseGridColumnBuilder } from "../base/BaseGridColumnBuilder";

export interface ProcessesGridColumnBuilderProps {
  canEdit: boolean;
  organisationId: number;
  projectId: number;
  userCanViewImpacts: boolean;
  columns: string[];
  onFieldUpdate: () => void;
}

export class ProcessesGridColumnBuilder extends BaseGridColumnBuilder {
  gridColumns: Dictionary<ColDef>;
  gridToastService = GridToastService;
  httpProgress = ProgressIndicatorModel;
  gridProps: ProcessesGridColumnBuilderProps;
  columnDefs: Dictionary<() => ColDef>;
  organisationId: number;

  constructor(gridProps: ProcessesGridColumnBuilderProps) {
    super(ProcessesApi.updateField, gridProps.organisationId, gridProps.projectId, gridProps.canEdit);
    this.gridProps = gridProps;
    this.organisationId = gridProps.organisationId;
    this.init();
  }

  private init = () => {
    this.columnDefs = {
      [CommonColDefFieldNamesEnum.Selected]: () =>
        new SelectionColumnBuilder().makeSelectable().generateColumnOptions(),
      [ProcessColDefFieldNamesEnum.UniqueIdentifier]: () => this.buildUniqueIdentifierColumn(),
      [ProcessColDefFieldNamesEnum.Level1]: () =>
        this.buildLevelColumn(ProcessColDefFieldNamesEnum.Level1, I18n.t("grids.level1"), ProcessHierarchField.LEVEL_1),
      [ProcessColDefFieldNamesEnum.Level2]: () =>
        this.buildLevelColumn(ProcessColDefFieldNamesEnum.Level2, I18n.t("grids.level2"), ProcessHierarchField.LEVEL_2),
      [ProcessColDefFieldNamesEnum.Level3]: () =>
        this.buildLevelColumn(ProcessColDefFieldNamesEnum.Level3, I18n.t("grids.level3"), ProcessHierarchField.LEVEL_3),
      [ProcessColDefFieldNamesEnum.Level4]: () =>
        this.buildLevelColumn(ProcessColDefFieldNamesEnum.Level4, I18n.t("grids.level4"), ProcessHierarchField.LEVEL_4),
      [ProcessColDefFieldNamesEnum.Level5]: () =>
        this.buildLevelColumn(ProcessColDefFieldNamesEnum.Level5, I18n.t("grids.level5"), ProcessHierarchField.LEVEL_5),
      [ProcessColDefFieldNamesEnum.ScopeItems]: () =>
        this.buildPillsColumn("scopeItemNames", I18n.t("grids.scopeItemNames")).generateColumnOptions()
    };
  };

  generateColumnDefs = (): ColDef[] => {
    let res: ColDef[] = [];
    this.gridProps.columns.forEach(e => {
      res.push(this.columnDefs[e]());
    });

    return res;
  };

  buildUniqueIdentifierColumn = () => {
    let model = new SimpleTextColumnBuilder({
      field: "uniqueIdentifier",
      headerName: I18n.t("grids.uniqueIdentifier")
    })
      .makeSelectable()
      .makeEditable();

    if (this.gridProps.canEdit) {
      model.createValueSetter(this.updateProcessField(ProcessHierarchField.UNIQUE_IDENTIFIER));
    }
    return model.generateColumnOptions();
  };

  buildLevelColumn = (
    fieldName: ProcessColDefFieldNamesEnum,
    headerName: string,
    fieldPosition: ProcessHierarchField
  ) => {
    let model = new SimpleTextColumnBuilder({ field: fieldName, headerName: headerName }).makeSelectable();

    if (this.gridProps.canEdit) {
      model.createValueSetter(this.updateProcessField(fieldPosition));
    }
    return model.generateColumnOptions();
  };

  updateProcessField = (field: ProcessHierarchField) => async (entityId: number, text: string) => {
    await this.updateTextField(field, entityId, text);
    this.gridProps.onFieldUpdate();
  };
}
