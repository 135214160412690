import { ColDef } from "ag-grid-community";
import ProgressIndicatorModel from "../../../../../../components/widgets/ProgressIndicator/ProgressIndicator_model";
import { ProjectProcessField } from "../../../../../../enums";
import ProjectProcessesApi from "../../../../../../services/api/v2/projectProcesses/ProjectProcesses.api";
import GridToastService from "../../../../../../services/local/gridToastService/GridToastService";
import I18n from "../../../../../localization/I18n";
import {
  CommonColDefFieldNamesEnum,
  ImpactColDefFieldNamesEnum,
  ProcessColDefFieldNamesEnum,
  ProjectProcessColDefFieldNamesEnum
} from "../../../enums/AgGridColDefFieldNameEnum";
import { AutocompleteColumnBuilder } from "../../columns/commonColumns/AutocompleteColumn/AutocompleteColumn_builder";
import {
  AUTOCOMPLETE_COLUMN_CONFIG,
  AUTOCOMPLETE_FILTER_CONFIG,
  mapProgressStatuses
} from "../../columns/commonColumns/AutocompleteColumn/AutocompleteColumn_config";
import {
  PROGRESS_STATUS_COLUMN_CONFIG,
  PROGRESS_STATUS_FILTER_CONFIG
} from "../../columns/commonColumns/AutocompleteColumn/progressStatus/ProgressStatusColumn_config";
import { DateColumnBuilder } from "../../columns/commonColumns/DateColumn/DateColumn_builder";
import { NameColumnBuilder } from "../../columns/commonColumns/NameColumn/NameColumn_builder";
import { NoteCountColumnBuilder } from "../../columns/commonColumns/NoteCountColumn/NoteCountColumn_builder";
import { NOTE_COUNT_COLUMN_CONFIG } from "../../columns/commonColumns/NoteCountColumn/NoteCountColumn_config";
import { SelectionColumnBuilder } from "../../columns/commonColumns/SelectionColumn/SelectionColumn_builder";
import { SimpleTextColumnBuilder } from "../../columns/commonColumns/SimpleTextColumn/SimpleTextColumn_builder";
import { ProcessHierarchField } from "../../utils/GridFields";
import { BaseGridColumnBuilder } from "../base/BaseGridColumnBuilder";
import {
  SHOW_IMPACTS_SIDEMODAL,
  SHOW_IMPACT_GROUP_SIDEMODAL,
  SHOW_NOTES_SIDEMODAL,
  SHOW_PROCESS_APP_SIDEMODAL,
  SHOW_PROCESS_ROLE_SIDEMODAL,
  SHOW_STAKEHOLDER_OWNERS_SIDEMODAL,
  SHOW_TAGS_SIDEMODAL
} from "./ProjectProcessesGridView_modals";
import { PROJECT_PROCESS_LOCATIONS_SIDEBAR_MODAL_CONFIG } from "./modals/projectProcessLocationsSidebar/ProjectProcessLocationsSidebarModal_config";
import { BASE_FILTER_CONFIG } from "../../columns/baseColumn/BaseColumn_config";

export interface ProjectProcessesGridColumnBuilderProps {
  canEdit: boolean;
  organisationId: number;
  projectId: number;
  userCanViewImpacts: boolean;
  columns: string[];
  onFieldUpdate: () => void;
}

export class ProjectProcessesGridColumnBuilder extends BaseGridColumnBuilder {
  gridColumns: Dictionary<ColDef>;
  gridToastService = GridToastService;
  httpProgress = ProgressIndicatorModel;
  gridProps: ProjectProcessesGridColumnBuilderProps;
  columnDefs: Dictionary<() => ColDef>;
  organisationId: number;

  constructor(gridProps: ProjectProcessesGridColumnBuilderProps) {
    super(ProjectProcessesApi.updateField, gridProps.organisationId, gridProps.projectId, gridProps.canEdit);
    this.gridProps = gridProps;
    this.organisationId = gridProps.organisationId;
    this.init();
  }

  private init = () => {
    this.columnDefs = {
      [CommonColDefFieldNamesEnum.Selected]: () =>
        new SelectionColumnBuilder().makeSelectable().generateColumnOptions(),
      [ProcessColDefFieldNamesEnum.UniqueIdentifier]: () => this.buildUniqueIdentifierColumn(),
      [ProcessColDefFieldNamesEnum.Level1]: () =>
        this.buildLevelColumn(ProcessColDefFieldNamesEnum.Level1, I18n.t("grids.level1")),
      [ProcessColDefFieldNamesEnum.Level2]: () =>
        this.buildLevelColumn(ProcessColDefFieldNamesEnum.Level2, I18n.t("grids.level2")),
      [ProcessColDefFieldNamesEnum.Level3]: () =>
        this.buildLevelColumn(ProcessColDefFieldNamesEnum.Level3, I18n.t("grids.level3")),
      [ProcessColDefFieldNamesEnum.Level4]: () =>
        this.buildLevelColumn(ProcessColDefFieldNamesEnum.Level4, I18n.t("grids.level4")),
      [ProcessColDefFieldNamesEnum.Level5]: () =>
        this.buildLevelColumn(ProcessColDefFieldNamesEnum.Level5, I18n.t("grids.level5")),
      [CommonColDefFieldNamesEnum.ProgressStatus]: () => this.buildProgressStatusColumn(),
      [ProcessColDefFieldNamesEnum.IsInScope]: () => this.buildIsInScopeColumn(),
      [ProcessColDefFieldNamesEnum.ScopeItems]: () =>
        this.buildPillsColumn(
          ProcessColDefFieldNamesEnum.ScopeItems,
          I18n.t("grids.scopeItemNames")
        ).generateColumnOptions(),
      [ProjectProcessColDefFieldNamesEnum.ProcessApps]: () =>
        this.buildPillsColumn(
          ProjectProcessColDefFieldNamesEnum.ProcessApps,
          I18n.t("grids.processApps"),
          SHOW_PROCESS_APP_SIDEMODAL
        ).generateColumnOptions(),
      [ProjectProcessColDefFieldNamesEnum.ProcessRoles]: () =>
        this.buildPillsColumn(
          ProjectProcessColDefFieldNamesEnum.ProcessRoles,
          I18n.t("grids.processRoles"),
          SHOW_PROCESS_ROLE_SIDEMODAL
        ).generateColumnOptions(),
      [ProjectProcessColDefFieldNamesEnum.StakeholderOwners]: () =>
        this.buildPillsColumn(
          ProjectProcessColDefFieldNamesEnum.StakeholderOwners,
          I18n.t("grids.stakeholderOwners"),
          SHOW_STAKEHOLDER_OWNERS_SIDEMODAL
        ).generateColumnOptions(),
      [ProjectProcessColDefFieldNamesEnum.ProcessJobRoles]: () =>
        this.buildPillsColumn(
          ProjectProcessColDefFieldNamesEnum.ProcessJobRoles,
          I18n.t("grids.processJobRoles")
        ).generateColumnOptions(),
      [ProjectProcessColDefFieldNamesEnum.ImpactGroups]: () =>
        this.buildPillsColumn(
          ProjectProcessColDefFieldNamesEnum.ImpactGroups,
          I18n.t("grids.impactGroups"),
          SHOW_IMPACT_GROUP_SIDEMODAL
        ).generateColumnOptions(),
      [ProjectProcessColDefFieldNamesEnum.Impacts]: () =>
        this.buildPillsColumn(
          ProjectProcessColDefFieldNamesEnum.Impacts,
          I18n.t("grids.impacts"),
          SHOW_IMPACTS_SIDEMODAL
        ).generateColumnOptions(),
      [ProjectProcessColDefFieldNamesEnum.Actions]: () =>
        this.buildPillsColumn(
          ProjectProcessColDefFieldNamesEnum.Actions,
          I18n.t("grids.actions")
        ).generateColumnOptions(),
      [ImpactColDefFieldNamesEnum.Locations]: () =>
        this.buildPillsColumn(
          ImpactColDefFieldNamesEnum.Locations,
          I18n.t("grids.locations"),
          PROJECT_PROCESS_LOCATIONS_SIDEBAR_MODAL_CONFIG
        ).generateColumnOptions(),
      [CommonColDefFieldNamesEnum.NoteCount]: () => this.buildNoteCountColumn(),
      [ProjectProcessColDefFieldNamesEnum.Tags]: () =>
        this.buildPillsColumn(
          ProjectProcessColDefFieldNamesEnum.Tags,
          I18n.t("grids.tags"),
          SHOW_TAGS_SIDEMODAL
        ).generateColumnOptions(),
      [CommonColDefFieldNamesEnum.CreatedBy]: () =>
        new NameColumnBuilder({
          field: CommonColDefFieldNamesEnum.CreatedBy,
          headerName: I18n.t("grids.createdBy"),
          pinned: false
        })
          .makeEditable(false)
          .makeReadOnly()
          .generateColumnOptions(),
      [CommonColDefFieldNamesEnum.CreatedAt]: () =>
        new DateColumnBuilder({ field: CommonColDefFieldNamesEnum.CreatedAt, headerName: I18n.t("grids.createdOn") })
          .makeEditable(false)
          .makeReadOnly()
          .withCellEditor(CommonColDefFieldNamesEnum.CreatedAt, "")
          .withComparator()
          .setValueFormatter(CommonColDefFieldNamesEnum.CreatedAt)
          .generateColumnOptions(),
      [CommonColDefFieldNamesEnum.ModifiedBy]: () =>
        new NameColumnBuilder({
          field: CommonColDefFieldNamesEnum.ModifiedBy,
          headerName: I18n.t("grids.lastModifiedBy"),
          pinned: false
        })
          .makeEditable(false)
          .makeReadOnly()
          .generateColumnOptions(),
      [CommonColDefFieldNamesEnum.UpdatedAt]: () =>
        new DateColumnBuilder({
          field: CommonColDefFieldNamesEnum.UpdatedAt,
          headerName: I18n.t("grids.lastModifiedOn")
        })
          .makeEditable(false)
          .makeReadOnly()
          .withCellEditor(CommonColDefFieldNamesEnum.UpdatedAt, "")
          .withComparator()
          .setValueFormatter(CommonColDefFieldNamesEnum.UpdatedAt)
          .generateColumnOptions()
    };
  };

  generateColumnDefs = (): ColDef[] => {
    let res: ColDef[] = [];
    this.gridProps.columns.forEach(e => {
      res.push(this.columnDefs[e]());
    });

    return res;
  };

  buildProgressStatusColumn = () => {
    const progressStatuses = mapProgressStatuses();
    let model = new AutocompleteColumnBuilder()
      .setColumnOptions(AUTOCOMPLETE_COLUMN_CONFIG({ field: "progressStatus", headerName: I18n.t("grids.status") }))
      .setFilterOptions({
        ...AUTOCOMPLETE_FILTER_CONFIG,
        ...PROGRESS_STATUS_FILTER_CONFIG
      })
      .makeSelectable(this.gridProps.canEdit)
      .makeEditable(this.gridProps.canEdit)
      .withCellEditor()
      .setValueSetter(params => {
        if (!params.newValue) return false;

        params.data.progressStatus = +params.newValue.key;
        this.updateProgressStatus(params.data.id, params.newValue.key);

        return true;
      })
      .withCellRenderer(params => {
        if (!params.data.progressStatus || !progressStatuses) return null;
        const progress = progressStatuses.find(e => e.key === params.data.progressStatus + "");

        if (!progress) return "";
        return <>{progress.label}</>;
      })
      .setColumnOptions(PROGRESS_STATUS_COLUMN_CONFIG(progressStatuses));

    return model.generateColumnOptions();
  };

  buildIsInScopeColumn = () => {
    const options = [
      { key: "yes", label: "Yes" },
      { key: "no", label: "No" }
    ];
    let model = new AutocompleteColumnBuilder()
      .setColumnOptions(
        AUTOCOMPLETE_COLUMN_CONFIG({
          field: ProcessColDefFieldNamesEnum.IsInScope,
          headerName: I18n.t("grids.inScope")
        })
      )
      .setFilterOptions({
        ...AUTOCOMPLETE_FILTER_CONFIG
        // ...PROGRESS_STATUS_FILTER_CONFIG
      })
      .makeSelectable(this.gridProps.canEdit)
      .makeEditable(this.gridProps.canEdit)
      .withCellEditor()
      .setValueSetter(params => {
        if (!params.newValue) return false;

        params.data.progressStatus = +params.newValue.key;
        this.updateIsInScope(params.data.id, params.newValue.key);

        return true;
      })
      .setColumnOptions(PROGRESS_STATUS_COLUMN_CONFIG(options));

    return model.generateColumnOptions();
  };

  buildUniqueIdentifierColumn = () => {
    let model = new SimpleTextColumnBuilder({
      field: "uniqueIdentifier",
      headerName: I18n.t("grids.uniqueIdentifier")
    })
      .setFilterOptions(BASE_FILTER_CONFIG)
      .makeSelectable()
      .makeEditable(false)
      .makeReadOnly();

    if (this.gridProps.canEdit) {
      model.createValueSetter(this.updateProjectProcessField(ProcessHierarchField.UNIQUE_IDENTIFIER));
    }
    return model.generateColumnOptions();
  };

  buildLevelColumn = (fieldName: ProcessColDefFieldNamesEnum, headerName: string) => {
    let model = new SimpleTextColumnBuilder({ field: fieldName, headerName: headerName }).makeSelectable();
    return model.generateColumnOptions();
  };

  buildLevel1Column = () => {
    let model = new SimpleTextColumnBuilder({ field: "level1", headerName: I18n.t("grids.level1") })
      .makeSelectable(this.gridProps.canEdit)
      .makeEditable(this.gridProps.canEdit)
      .setFilterOptions(BASE_FILTER_CONFIG)
      .makeReadOnly(!this.gridProps.canEdit);

    if (this.gridProps.canEdit) {
      model.createValueSetter(this.updateProjectProcessField(ProcessHierarchField.LEVEL_1));
    }
    return model.generateColumnOptions();
  };

  buildLevel2Column = () => {
    let model = new SimpleTextColumnBuilder({ field: "level2", headerName: I18n.t("grids.level2") })
      .makeSelectable(this.gridProps.canEdit)
      .makeEditable(this.gridProps.canEdit)
      .setFilterOptions(BASE_FILTER_CONFIG)
      .makeReadOnly(!this.gridProps.canEdit);

    if (this.gridProps.canEdit) {
      model.createValueSetter(this.updateProjectProcessField(ProcessHierarchField.LEVEL_2));
    }
    return model.generateColumnOptions();
  };

  buildLevel3Column = () => {
    let model = new SimpleTextColumnBuilder({ field: "level3", headerName: I18n.t("grids.level3") })
      .makeSelectable(this.gridProps.canEdit)
      .makeEditable(this.gridProps.canEdit)
      .setFilterOptions(BASE_FILTER_CONFIG)
      .makeReadOnly(!this.gridProps.canEdit);

    if (this.gridProps.canEdit) {
      model.createValueSetter(this.updateProjectProcessField(ProcessHierarchField.LEVEL_3));
    }
    return model.generateColumnOptions();
  };

  buildNoteCountColumn = () => {
    let model = new NoteCountColumnBuilder().setColumnOptions(NOTE_COUNT_COLUMN_CONFIG()).makeSelectable();

    if (this.gridProps.canEdit) {
      const projectProcessNotesModal = (projectProcess: FP.Entities.IProjectProcess) =>
        this.gridModalBuilder
          .constructSideModal()
          .setModalOptions(SHOW_NOTES_SIDEMODAL(this.organisationId, this.projectId, projectProcess))
          .generateModal();

      model.setEditableOnDoubleClick(projectProcessNotesModal);
    }
    return model.generateColumnOptions();
  };

  updateProjectProcessField = (field: ProcessHierarchField) => async (entityId: number, text: string) => {
    await this.updateTextField(field, entityId, text);
    this.gridProps.onFieldUpdate();
  };

  updateProgressStatus = async (entityId: number, data: any) => {
    await this.updateIdField(ProjectProcessField.PROGRESS_STATUS, entityId, data);
  };

  updateIsInScope = async (entityId: number, data: any) => {
    await this.updateIdField(ProjectProcessField.IS_IN_SCOPE, entityId, data === "yes" ? 1 : 0);
  };
}
