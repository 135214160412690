import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useCurrentOrganisationId } from "../../../../../../../../services/local/organisationContext/OrganisationContextModel";
import { AudienceActionsModalModel } from "./AudienceActionsModal_model";
import { PositionedSpinner } from "../../../../../../../../components/ui/PositionedSpinner";
import { observer } from "mobx-react-lite";
import { ActionsGrid } from "../../../ActionsGrid/ActionsGridView_view";

export const AudienceActionsModal: React.FC<{ selectedActionIds: number[]; selectedAudienceIds: number[] }> = observer(
  ({ selectedActionIds, selectedAudienceIds }) => {
    const { projectId } = useParams<{ projectId: string }>();
    const organisationId = useCurrentOrganisationId();
    const [model] = useState(
      () => new AudienceActionsModalModel(organisationId, +projectId, selectedActionIds, selectedAudienceIds)
    );

    useEffect(() => {
      model.onMount();

      //eslint-disable-next-line
    }, []);
    if (model.isLoading) return <PositionedSpinner />;
    return (
      <div className="processes-modal-view insight-tab-content__space-filler" style={{ height: "100%" }}>
        <ActionsGrid
          data={model.data}
          gridToolbarType="link-modal"
          isLoading={model.isLoading}
          onAssignSuccess={model.onAssignSuccess}
          preSelectedItemIds={selectedActionIds}
        />
      </div>
    );
  }
);
