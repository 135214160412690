import { useEffect, useRef, useState } from "react";
import { useCurrentOrganisationId } from "../../../../../../services/local/organisationContext/OrganisationContextModel";
import { useFlightPathUser } from "../../../../../auth_insight";
import { AppGrid } from "../base/AppGrid_view";
import { IF } from "../../../../../../components/hoc/If";
import { PositionedSpinner } from "../../../../../../components/ui/PositionedSpinner";
import { AppGridToolbar } from "../base/AppGridToolbar_view";
import { useParams } from "react-router-dom";
import { MilestonesGridViewModel } from "./MilestonesGridView_model";
import { observer } from "mobx-react-lite";

export interface MilestonesGridViewProps {
  data: FP.Entities.IMilestone[];
  onMilestoneCreated: () => Promise<any>;
}

export const MilestonesGridView: React.FC<MilestonesGridViewProps> = observer(({ data, onMilestoneCreated }) => {
  const organisationId = useCurrentOrganisationId();
  const authUser = useFlightPathUser();
  const gridRef = useRef<AppGrid>();
  const { projectId } = useParams<{ projectId: string }>();

  const [gridModel, setGridModel] = useState(
    () => new MilestonesGridViewModel(organisationId, +projectId, authUser, onMilestoneCreated)
  );

  useEffect(() => {
    if (
      (typeof projectId !== "undefined" && gridModel.projectId !== +projectId) ||
      gridModel.organisationId !== organisationId
    ) {
      setGridModel(new MilestonesGridViewModel(organisationId, +projectId, authUser, onMilestoneCreated));
    }
    // eslint-disable-next-line
  }, [organisationId, projectId]);

  useEffect(() => {
    gridModel.onMount();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <IF condition={!data}>
        <PositionedSpinner />
      </IF>
      <IF condition={!!data}>
        <AppGridToolbar
          onQuichSearchInputChange={e => {
            gridRef.current.setSearchText(e.currentTarget.value);
          }}
          selectedItemCount={gridModel.selectedItems?.length}
          actions={gridModel.gridActions}
        />

        <AppGrid
          ref={gridRef}
          filterStoreAddon={gridModel.filterStoreAddon}
          columnStateAddon={gridModel.columnStateAddon}
          textWrapperAddon={gridModel.textWrapAddon}
          rowData={data}
          columnDefs={gridModel.gridColumns}
          onGridStateUpdate={gridModel.onGridStateUpdate}
          shouldTrackSelectedCell={true}
          rowSelection="multiple" // Options - allows click selection of rows
        />
      </IF>
    </>
  );
});
