import { observer } from "mobx-react";
import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { IF } from "../../../../../../components/hoc/If";
import { PositionedSpinner } from "../../../../../../components/ui/PositionedSpinner";
import { useCurrentOrganisationId } from "../../../../../../services/local/organisationContext/OrganisationContextModel";
import { useFlightPathUser } from "../../../../../auth_insight";
import { AppGridToolbarType } from "../../types/AppGrid_types";
import { AppGridToolbar } from "../base/AppGridToolbar_view";
import { AppGrid } from "../base/AppGrid_view";
import { ModalGridToolbar } from "../base/ModalGridToolbar_view";
import { ProcessGridModel } from "./ProcessesGridView_model";

interface ProcessesGridProps {
  data: any[];
  gridToolbarType?: AppGridToolbarType;
  onAssignSuccess?: (selectedItems: number[]) => void;
  selectedLinkItems?: number[];
  refreshDataHandler?: any;
  onFieldUpdate?: () => void;
  preSelectedItemIds?: number[];
}

export const ProcessesGrid: React.FunctionComponent<ProcessesGridProps> = observer(
  ({ data, gridToolbarType = "base", onAssignSuccess, refreshDataHandler, onFieldUpdate, preSelectedItemIds }) => {
    const organisationId = useCurrentOrganisationId();
    const authUser = useFlightPathUser();
    const gridRef = useRef<AppGrid>();
    const { projectId } = useParams<{ projectId: string }>();

    const [gridModel, setGridModel] = useState(
      () => new ProcessGridModel(organisationId, +projectId, authUser, refreshDataHandler, onFieldUpdate)
    );

    useEffect(() => {
      if (
        (typeof projectId !== "undefined" && gridModel.projectId !== +projectId) ||
        gridModel.organisationId !== organisationId
      ) {
        setGridModel(new ProcessGridModel(organisationId, +projectId, authUser, refreshDataHandler, onFieldUpdate));
      }
      // eslint-disable-next-line
    }, [organisationId, projectId]);

    useEffect(() => {
      gridModel.onMount();
      return gridModel.onUnmount;
      // eslint-disable-next-line
    }, []);

    return (
      <div className="insight-tab-content__space-filler h-100">
        <IF condition={!data}>
          <PositionedSpinner />
        </IF>
        <IF condition={!!data}>
          <IF condition={gridToolbarType === "all" || gridToolbarType === "link-modal"}>
            <ModalGridToolbar
              className="process-grid-modal"
              onQuichSearchInputChange={e => {
                gridRef?.current?.setSearchText(e.currentTarget.value);
              }}
              selectedItems={gridModel.selectedItems}
              preSelectedItemIds={preSelectedItemIds}
              onAssignSuccess={onAssignSuccess}
            />
          </IF>
          <IF condition={gridToolbarType === "all" || (gridToolbarType === "base" && !!gridModel.gridActions)}>
            <AppGridToolbar
              // connectedUsers={data.connectedUsers}
              onQuichSearchInputChange={e => {
                gridRef.current.setSearchText(e.currentTarget.value);
              }}
              selectedItemCount={gridModel.selectedItems?.length}
              actions={gridModel.gridActions}
            />
          </IF>
          <AppGrid
            ref={gridRef}
            filterStoreAddon={gridModel.filterStoreAddon}
            columnStateAddon={gridModel.columnStateAddon}
            textWrapperAddon={gridModel.textWrapAddon}
            rowData={data}
            columnDefs={gridModel.gridColumns}
            onGridStateUpdate={gridModel.onGridStateUpdate}
            shouldTrackSelectedCell={true}
          />
        </IF>
      </div>
    );
  }
);
