import { AudienceActionsModal } from "./AudienceActionsModal_view";

export const AUDIENCE_ACTIONS_SIDEBAR_MODAL_CONFIG = (organisationId: number, projectId: number, item: any) => {
  return {
    showClose: false,
    className: "d-flex flex-column",
    panelProps: {
      className: "d-flex flex-column h-100"
    },
    content: <AudienceActionsModal selectedAudienceIds={[item.id]} selectedActionIds={item.actions.map(e => e.id)} />
  };
};
