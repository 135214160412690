export enum ImpactGroupField {
  NAME = 1,
  DESCRIPTION = 2
}

export enum ProcessHierarchField {
  LEVEL_1 = 1,
  LEVEL_2 = 2,
  LEVEL_3 = 3,
  LEVEL_4 = 4,
  LEVEL_5 = 5,
  UNIQUE_IDENTIFIER = 4
}

export enum ImpactField {
  NAME = 1,
  DESCRIPTION = 2,
  OWNER = 3,
  START_DATE = 4,
  END_DATE = 5,
  PROGRESS_STATUS = 6,
  IMPACT_TYPE = 7,
  NUMBER_IMPACTED = 8,
  IMPACT_LEVEL = 9,
  MITIGATION_CONFIDENCE = 10
}
