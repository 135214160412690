import { action, makeObservable, observable } from "mobx";
import AudiencesApi, {
  AudiencesApi as IAudiencesApi
} from "../../../../../../../../services/api/v2/audiences/Audiences.api";
import ModalContext from "../../../../../../../modalZ/context/ModalContext";

export class AudiencesGridModalModel {
  organisationId: number;
  projectId: number;
  audiencesProvider: IAudiencesApi;
  @observable isLoading: boolean = true;
  @observable audiences: any[];
  @observable selectedItemIds: number[];
  itemIds: number[];

  constructor(organisationId: number, projectId: number, itemIds: number[], selectedItemIds: number[]) {
    makeObservable(this);
    this.organisationId = organisationId;
    this.projectId = projectId;
    this.audiencesProvider = AudiencesApi;
    this.selectedItemIds = selectedItemIds;
    this.itemIds = itemIds;
  }

  onMount = async () => {
    await this.loadAudiences();
    this.setIsLoading(false);
  };

  onUnmount = () => {};

  loadAudiences = async () => {
    const res = await this.audiencesProvider.getGridData(this.organisationId, this.projectId);

    if (!res.payload || res.isError) return;

    this.setAudiences(res.payload);
  };

  @action
  setIsLoading = (isLoading: boolean) => {
    this.isLoading = isLoading;
  };
  @action
  setAudiences = (audiences: any[]) => {
    this.audiences = audiences;
  };
}
